<template>
  <div class="creationCompetition">
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      style="margin-left: 11px"
      class="genera-breadcrumb"
    >
      <el-breadcrumb-item>直播实训题管理</el-breadcrumb-item>
      <el-breadcrumb-item>脚本管理</el-breadcrumb-item>
      <el-breadcrumb-item style="color:#060111">创建脚本</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="creationCompetitionProgress">
      <div class="competitionProgress">
        <div class="step-num">
          <span class="active">01</span>
          <span>创建脚本</span>
        </div>
        <img
          class="step-wire"
          :src="
            active > 1
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png')
          "
          alt=""
        />
        <div class="step-num">
          <span :class="active > 1 ? 'active' : ''">02</span>
          <span>直播列表数据</span>
        </div>
        <img
          class="step-wire"
          :src="
            active > 2
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png')
          "
          alt=""
        />
        <div class="step-num">
          <span :class="active > 2 ? 'active' : ''">03</span>
          <span>脚本信息</span>
        </div>
        <img
          class="step-wire"
          :src="
            active > 2
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png')
          "
          alt=""
        />
        <div class="step-num">
          <span :class="active > 3 ? 'active' : ''">04</span>
          <span>直播数据</span>
        </div>
        <img
          class="step-wire"
          :src="
            active > 3
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png')
          "
          alt=""
        />
        <div class="step-num">
          <span :class="active > 4 ? 'active' : ''">05</span>
          <span>创建成功</span>
        </div>
      </div>
    </div>
    <div
      class="creationCompetitionForm"
      style="min-height: calc(100vh - 330px)"
    >
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  name: "creationScript",
  data() {
    return {
      activeName: "raceAdmin",
      active: 1,
    };
  },
  watch: {
    $route(route) {
      if (route.name == "two") {
        this.active = 2;
      } else if (route.name == "three") {
        this.active = 3;
      } else if (route.name == "four") {
        this.active = 4;
      } else if (route.name == "success") {
        this.active = 5;
      } else {
        this.active = 1;
      }
    },
  },
  created() {
    if (this.$route.name == "two") {
      this.active = 2;
    } else if (this.$route.name == "three") {
      this.active = 3;
    } else {
      this.active = 1;
    }
  },
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.creationCompetition {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 30px 0 0 31px;
  overflow-y: auto;

  .creationCompetitionProgress {
    display: flex;
    .competitionProgress {
      margin: 54px auto;
      display: flex;
      align-items: center;
      .step-num {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        span:nth-child(1) {
          display: inline-block;
          width: 30px;
          height: 30px;
          font-size: 15px;
          color: #ffffff;
          background: #dcdcdc;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
        }
        .active {
          background: #1122d8 !important;
        }
        span:nth-child(2) {
          display: inline-block;
          width: 100px;
          text-align: center;
          top: 40px;

          position: absolute;
          font-size: 12px;
          color: #333333;
        }
      }
      .step-wire {
        height: 14px;
        margin: 0 10px;
      }
    }
  }
}
</style>
